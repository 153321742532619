<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>전자결재</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <CarrotTitle title="내 경비 관리">
                    - 매 달 경비 정산일 전까지 경비 입력을 완료한 뒤 ‘개인경비 제출’ 버튼을 통해 지출결의서를 제출해주세요.<br>
                    - 개인경비 결재 완료 시 수정 불가합니다.
                </CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>

                            <div class="mb-20 dp-table w-100per">
                                <select v-model="comp.search.year" class="w-80px" @change="comp.find">
                                    <option v-for="year in comp.years" :key="year" :value="year">{{year}}</option>
                                </select>
                                <select v-model="comp.search.month" class="w-70px ml-10" @change="comp.find">
                                    <option :value="m" v-for="m in comp.months" :key="m" >{{m}}월</option>
                                </select>
                                <router-link to="/documents/ExpenseInputAdd"><button class="btn-default float-right h-30px">경비입력</button></router-link>
                            </div>
                            
                            <div class="block-title mt-50 mb-20">개인</div>
                            <table class="table-col" v-if="comp.indiv!=null">
                                <colgroup>
                                    <col span="5">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>교통비(합계)</th>
                                        <th>식대</th>
                                        <th>유류비</th>
                                        <th>기타</th>
                                        <th class="total">총합계</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{comp.indiv.txt_indiv_expense}}</td>
                                        <td>{{comp.indiv.txt_indiv_meals}}</td>
                                        <td>{{comp.indiv.txt_indiv_fuel}}</td>
                                        <td>{{comp.indiv.txt_indiv_etc}}</td>
                                        <td class="total">{{comp.indiv.txt_indiv_total}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <div class="block-title mt-50 mb-20">법인</div>
                            <table class="table-col" v-if="comp.corp!=null">
                                <colgroup>
                                    <col span="5">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>교통비(합계)</th>
                                        <th>식대</th>
                                        <th>유류비</th>
                                        <th>기타</th>
                                        <th class="total">총합계</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{comp.corp.txt_corp_expense}}</td>
                                        <td>{{comp.corp.txt_corp_meals}}</td>
                                        <td>{{comp.corp.txt_corp_fuel}}</td>
                                        <td>{{comp.corp.txt_corp_etc}}</td>
                                        <td class="total">{{comp.corp.txt_corp_total}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="mt-50 mb-20">
                                <select v-model="comp.txt_filter" class="w-110px">
                                    <option value="">개인+법인</option>
                                    <option value="개인">개인</option>
                                    <option value="법인">법인</option>
                                </select>
                            </div>                            
                            <table class="table-col">
                                <colgroup>
                                    <col width="80">
                                    <col width="170">
                                    <col width="190">
                                    <col width="190">
                                    <col width="190">
                                    <col width="190">
                                    <col width="190">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>지출일</th>
                                        <th>개인/법인</th>
                                        <th>고객사</th>
                                        <th>구분</th>
                                        <th>비고</th>
                                        <th>금액</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="comp.list.length==0">
                                        <td colspan="7">데이터가 없습니다.</td>
                                    </tr>
                                    <template v-for="(f, idx) in comp.list" :key="idx">
                                        <tr v-if="f.ctype==comp.txt_filter || comp.txt_filter==''">
                                            <td>{{idx+1}}</td>
                                            <td><span class="btn-view" @click="comp.modify(f.idx)">{{f.cdate}}</span></td>
                                            <td>{{f.ctype}}</td>
                                            <td>{{f.company}}</td>
                                            <td>{{f.cat}}</td>
                                            <td>{{f.memo}}</td>
                                            <td>{{f.cost}}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <button v-if="comp.indiv!=null && comp.indiv.txt_indiv_total>'0'" @click="comp.submitExpense('개인')" class="btn-default float-right mt-30 ml-20" ><p class="mt-5 mb-5">개인경비(개인) 제출</p><p v-if="comp.check_indiv==''" class="font-13 color-1">미제출</p><p v-if="comp.check_indiv!=''" class="font-13 color-1">{{comp.check_indiv}} 제출완료</p></button>
                            <button v-if="comp.corp!=null && comp.corp.txt_corp_total>'0'" @click="comp.submitExpense('법인')" class="btn-default float-right mt-30"><p class="mt-5 mb-5">개인경비(법인) 제출</p><p v-if="comp.check_corp==''" class="font-13 color-1">미제출</p><p v-if="comp.check_corp!=''" class="font-13 color-1">{{comp.check_corp}} 제출완료</p></button>

                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router'


export default {
    setup() {
        const toast = useToast();
        const router = useRouter();
        const comp = reactive({
            search : {
                year : "2021",
                month : "9",
            },
            years : [],
            months : [],
            txt_filter : "",
            list : [],
            indiv : null,
            corp : null,
            check_corp : "",
            check_indiv : "",

            submitExpense : (ctype) => {
                let param = {params : comp.search};
                param.params.ctype = ctype;
                axios.get('/rest/expense/sendDocument', param).then((res)=>{
                    if( res.data.err == 0 ){
                        router.push({ 
                            name:"documents-Document-id",
                            params : { id:res.data.idx }
                        });

                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
                // router.push({ 
                //     name:"documents-DraftingAdd",
                //     params : {id : 'asdf'}
                // });

            },

            find : () => {
                let param = {params : comp.search};
                axios.get('/rest/expense/myExpense', param).then((res)=>{
                    if( res.data.err == 0 ){
                        comp.list = res.data.list;
                        comp.indiv = res.data.indiv;
                        comp.corp = res.data.corp;
                        comp.check_corp = res.data.check_corp;
                        comp.check_indiv = res.data.check_indiv;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            modify : (idx) => {
                router.push({ 
                    path:"/documents/ExpenseInputModify/" + idx
                });
            },
        });

        onMounted(() => {
            comp.years = [];
            let ndate = new Date();
            let syear = ndate.getFullYear()-3;
            let eyear = ndate.getFullYear();
            comp.search.month = ndate.getMonth() + 1;

            comp.search.year = eyear;
            for(let i=syear;i<=eyear;i++) {
                comp.years.push(i);
            }
            for(let i=1;i<=12;i++) {
                comp.months.push(i);
            }
            comp.find();
            
        });

        return {comp};
    }

}
</script>

<style lang="scss" scoped>
</style>